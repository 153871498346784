import styled from 'styled-components';
import { cssVars } from '@/styles/Themes/cssVars';
import Palette from '@/styles/Themes/Palette';
import { radius } from '@/styles/Themes/Metrics';
import Screen from '@/styles/Themes/Screen';
import Text from '@/styles/Themes/Text';
import { Today } from '@/shared/images/icons/Today';
import { Tomorrow } from '@/shared/images/icons/Tomorrow';
import { SouldOut } from '@/shared/images/icons/Soldout';

export const AvailabilityThumb = styled.div<{ today: boolean; soldout?: boolean }>`
    position: absolute;
    right: 70px;
    bottom: -18px;
    left: 21px;
    min-height: 36px;
    color: ${Palette.white.normal};
    ${Text.Full.medium.medium}
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    z-index: 5;
    align-items: center;
    border-radius: ${radius.large};
    box-shadow: 0px 4px 23px ${cssVars.shadow_normal};
    background-color: ${({ today, soldout }) =>
        (today && Palette.green.normal) || (soldout && Palette.state.error) || '#979797'};
    ${Screen.ipad} {
        left: unset;
        width: ${({ soldout }) => (soldout ? '280px' : '250px')};
    }
`;

export const TomorrowIcon = styled(Tomorrow)`
    margin-right: 3px;
    & path {
        fill: #fff;
    }
`;
export const SouldOutIcon = styled(SouldOut)`
    margin-right: 3px;
`;
export const TodayIcon = styled(Today)`
    margin-right: 3px;
`;

// export const NewToTripshock = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     position: absolute;
//     top: 25px;
//     right: -45px;
//     rotate: 45deg;
//     background-color: ${cssVars.bg_white_dark3};
//     width: 175px;
//     height: 34px;
//     color: ${Palette.green.normal};
//     ${Text.Full.small.semibold};
//     padding: 3px 65px;
//     text-align: center;
//     z-index: 5;
//     ${Screen.desktop} {
//         height: 40px;
//         width: 196px;
//         right: -55px;
//     }
// `;
