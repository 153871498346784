import { memo, ReactElement, useMemo } from 'react';
import { format, addDays } from 'date-fns';
import { SERVER_FORMAT_DATE } from 'utils/constants';
import * as S from './AvailabilityThumb.styles';
import { IDaysAvailability } from '@/common/service/api/Availability/Availability.domain';

type AvailabilityThumbProps = {
    daysAvailability?: IDaysAvailability;
};

const AvailabilityThumb = ({ daysAvailability }: AvailabilityThumbProps): ReactElement => {
    const args = useMemo(() => {
        if (
            !daysAvailability ||
            typeof daysAvailability !== 'object' ||
            daysAvailability.length < 1
        ) {
            return null;
        }

        const now = new Date();
        const today = format(now, SERVER_FORMAT_DATE);
        const isAvailToday = !!daysAvailability[today];

        if (isAvailToday) {
            return {
                isAvailToday,
            };
        }

        const isAvailTomorrow = !!daysAvailability[format(addDays(now, 1), SERVER_FORMAT_DATE)];

        if (isAvailTomorrow) {
            return {
                isAvailTomorrow,
            };
        }

        return {
            isAvailToday: false,
            isAvailTomorrow: false,
        };
    }, [daysAvailability]);

    if (!args) {
        return <></>;
    }

    const { isAvailToday, isAvailTomorrow } = args;

    if (!isAvailToday && !isAvailTomorrow) {
        return <></>;
    }

    return (
        <>
            {/* {!!price && !isSoldOut && <S.NewToTripshock>Save now!</S.NewToTripshock>} */}
            <S.AvailabilityThumb
                data-test-id="availability-thumb"
                today={isAvailToday || isAvailTomorrow}
            >
                {isAvailToday && (
                    <>
                        <S.TodayIcon /> Experience today
                    </>
                )}
                {isAvailTomorrow && (
                    <>
                        <S.TomorrowIcon /> Experience tomorrow
                    </>
                )}
                {/* {isAvailNearest && (
                    <>
                        <S.TomorrowIcon />
                        {` Next Available: ${nearestFormattedDate}`}
                    </>
                )} */}
                {/* {isSoldOut && (
                    <>
                        <S.SouldOutIcon />
                        <b>Sold out&nbsp;</b> - try adjusting your dates
                    </>
                )} */}
            </S.AvailabilityThumb>
        </>
    );
};

export default memo(AvailabilityThumb);
