import { ReactElement, memo, useCallback } from 'react';
import * as S from './ImagesGallery.styles';
import { IActivityWithAvailability } from '@/common/domain/Merge.domain';
import AvailabilityThumb from '../ActivitiesPreview/AvailabilityThumb/AvailabilityThumb';
import { useWishlistContext } from '@/common/app/contexts/WishListContext';
import { numberFormatPrice } from '@/common/app/utils/numberUtils';

type ImageLabelsProps = {
    activity: IActivityWithAvailability;
    reviewsCount?: number;
    disabled?: boolean;
    position: number;
    item_list_id: string;
    item_list_name: string;
};

export const ImageLabelsContainer = ({
    activity,
    disabled,
    position,
    item_list_name,
    item_list_id,
}: ImageLabelsProps): ReactElement => {
    const { addOrRemoveWishlist, isActivityInWishlist } = useWishlistContext();

    const handleWishlist = useCallback(
        (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
            e.preventDefault();
            e.stopPropagation();

            if (addOrRemoveWishlist)
                addOrRemoveWishlist(activity, position, item_list_id, item_list_name);
        },
        [activity, addOrRemoveWishlist, item_list_id, item_list_name, position]
    );

    const priceStrikeOut = activity?.price_strike_out
        ? activity?.price_strike_out.toLocaleString('en-IN')
        : '';

    const price = numberFormatPrice(activity?.price, { maximumSignificantDigits: 3 });

    return (
        <>
            {!disabled && !!activity?.price && (
                <S.PriceWrapper data-test-id="Activity_card_price">
                    {activity?.price_strike_out && <S.StrikeOut>{priceStrikeOut}</S.StrikeOut>}
                    &nbsp;
                    <S.Price>{price}</S.Price>
                </S.PriceWrapper>
            )}
            <S.IconWrapper onClick={handleWishlist} data-test-id="Wishlist_button">
                {isActivityInWishlist(activity.id) ? <S.WishListBtnFilled /> : <S.WishListBtn />}
            </S.IconWrapper>
            <AvailabilityThumb daysAvailability={activity?.days_availability} />
        </>
    );
};

export const ImageLabels = memo(ImageLabelsContainer);
